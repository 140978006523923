const routes = [
  {
    path: 'bs',
    name: 'Booking',
    component: () => import('pages/bookingSystem/IndexV2.vue'),
    meta: {
      title: 'BS',
      subtitle: '預約系統',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 3 }]
    }
  },
  {
    path: 'bs/logs',
    name: 'BookingLogs',
    component: () => import('pages/bookingSystem/Logs.vue'),
    meta: {
      title: 'BookingLogs',
      subtitle: '預約紀錄',
      icon: 'mdi-format-list-bulleted-type',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 2 }]
    }
  },
  {
    path: 'bs-dashboard',
    name: 'BS-Dashboard',
    component: () => import('pages/bookingSystem/Dashboard.vue'),
    meta: {
      title: 'BS-Dashboard',
      subtitle: '預約管理',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 7 }]
    }
  },
  {
    path: 'bs-dashboard/category',
    name: 'BS-Category',
    component: () => import('pages/bookingSystem/Category.vue'),
    meta: {
      title: 'BS-Category',
      subtitle: '預約分類管理',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 7 }]
    }
  },
  {
    path: 'bs-dashboard/item',
    name: 'BS-Item',
    component: () => import('pages/bookingSystem/Item.vue'),
    meta: {
      title: 'BS-Item',
      subtitle: '預約項目管理',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 7 }]
    }
  },
  {
    path: 'bs-dashboard/makeup-guest',
    name: 'BS-MakeUpGuest',
    component: () => import('pages/bookingSystem/MakeUpGuest.vue'),
    meta: {
      title: 'BS-MakeUpGuest',
      subtitle: '補登訪客預約',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 3 }]
    }
  },
  {
    path: 'bs-dashboard/topic',
    name: 'BS-Topic',
    component: () => import('pages/bookingSystem/Topic.vue'),
    meta: {
      title: 'BS-Topic',
      subtitle: '預約主題',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 7 }]
    }
  },
  {
    path: 'bs-dashboard/quick-start',
    name: 'BS-QuickStart',
    component: () => import('pages/bookingSystem/QuickStart.vue'),
    meta: {
      title: 'BS-QuickStart',
      subtitle: '快速開始',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'MRB', crud: 7 }]
    }
  }
]

export default routes
