export function openDialog(context, payload) {
  context.commit('openDialog', payload)
}

export function closeDialog(context, payload) {
  context.commit('closeDialog', payload)
}

export function settingKeepAlive(context, payload) {
  context.commit('settingKeepAlive', payload)
}
