const routes = [
  {
    path: 'cms',
    component: () => import('pages/cms/Index.vue'),
    children: [
      {
        path: 'page-templates',
        name: 'CmsPageTemplates',
        component: () => import('pages/cms/templates/Index.vue')
      },
      {
        path: 'page-templates/edit',
        name: 'CmsPageTemplatesEdit',
        component: () => import('pages/cms/templates/Edit.vue')
      }
    ]
  }
]

export default routes
