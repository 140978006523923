const ArticleNew = process.env.MLSHB_ARTICLE_NEW ? process.env.MLSHB_ARTICLE_NEW : 'ArticleNew'
const routes = [
  {
    path: 'mlshb/analysis',
    name: 'MLSHBAnalysis',
    component: () => import('pages/mlshb/005/Analysis.vue'),
    meta: {
      title: '三高',
      subtitle: 'Hypertension, Hyperlipidemia and Diabetes Mellitus',
      icon: 'search'
    }
  },
  {
    path: 'mlshb/article-new',
    name: 'MLSHBArticleNew',
    component: () => import('pages/mlshb/article/' + ArticleNew + '.vue'),
    meta: {
      title: 'ArticleNew',
      subtitle: '新增內容',
      icon: 'chrome_reader_mode',
      requiresAuth: true
    }
  },
  {
    path: 'mlshb/chief-mail',
    name: 'MLSHB-ChiefMail',
    component: () => import('pages/mlshb/chiefMail/Index.vue'),
    meta: {
      title: 'ChiefMail',
      subtitle: '局長信箱',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: 'mlshb/article-approval',
    name: 'MLSHB-ArticleApproval',
    component: () => import('pages/mlshb/article/Approval.vue')
  },
  {
    path: 'mlshb/bulletins',
    name: 'MLSHB-Bulletins',
    component: () => import('pages/mlshb/bulletin/Index.vue')
  },
  {
    path: 'mlshb/bulletin-approval',
    name: 'MLSHB-BulletinApproval',
    component: () => import('pages/mlshb/bulletin/Approval.vue')
  },
  {
    path: 'mlshb/tag-transfer',
    name: 'MLSHBTagTransfer',
    component: () => import('pages/mlshb/article/TagTransfer.vue'),
    meta: {
      title: '文章分類批次轉換',
      subtitle: 'Article Tag Transfer',
      icon: 'search'
    }
  }
]

export default routes
