import Vue from 'vue'
import VueRouter from 'vue-router'
import localforage from 'localforage'

import routes from './routes'
import { getSessionByToken } from 'boot/axiosAPI/auth'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

async function isAuthenticate() {
  try {
    const token = window.localStorage.getItem('token')
    if (!token) {
      return false
    }
    const { data } = await getSessionByToken(token)
    console.log(data)
    if (data.returnCode !== '000000') {
      return false
    }
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export default function ({ store }/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })
  Router.beforeEach(async (to, from, next) => {
    // ...
    const token = window.localStorage.getItem('token')
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (token) {
        const isAuth = await isAuthenticate()
        const profile = await store.dispatch('profile/getMyProfile', window.localStorage.getItem('memberId'))
        if (isAuth && profile) {
          const { idDepartments } = profile
          if (localStorage.getItem('profile') === null) {
            localforage.setItem('profile', profile)
          }
          window.localStorage.setItem('idDepartments', idDepartments)
          next()
        } else {
          next({ name: 'Login' })
        }
      } else {
        next({ name: 'Login' })
      }
    } else {
      next()
    }
  })

  return Router
}
