const routes = [
  {
    path: 'v2/department',
    name: 'DepartmentV2',
    component: () => import('pages/v2/core/Department.vue'),
    meta: {
      title: 'Department',
      subtitle: '部門管理',
      icon: 'folder_shared',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 15 }]
    }
  },
  {
    path: 'v2/member',
    name: 'CoreMember',
    component: () => import('pages/v2/core/Member.vue'),
    meta: {
      title: 'Member',
      subtitle: '會員管理',
      icon: 'group',
      requiresAuth: true,
      microCode: 'COR',
      permissions: [{ microCode: 'COR', crud: 15 }]
    }
  }
]

export default routes
