function processBoolean(props, defaultProps) {
  if (props) {
    return (props === 'true')
  } else {
    return defaultProps
  }
}

export default function() {
  return {
    //
    title: process.env.TITLE ? process.env.TITLE : 'Backoffice System',
    welcome: process.env.WELCOME,
    version: process.env.VERSION,
    commitHash: process.env.COMMIT_HASH.substr(0, 7),
    isDEV: process.env.DEV,
    idelAutoLogout: process.env.IDEL_AUTO_LOGOUT,
    ticket: {
      idDepartmentRequired: processBoolean(process.env.TICKET_ID_DEPARTMENT_REQUIRED, true)
    },
    webSiteURL: process.env.WEB_SITE_URL || 'localhost:3000',
    cdcLogin: processBoolean(process.env.CDC_LOGIN, true),
    // cache keep alive
    includePage: [],
    // Labs
    departments: [],
    // Global Dialog status
    dialog: {
      widget: false,
      report: false,
      shortURL: false,
      zipCode: false
    }
  }
}
