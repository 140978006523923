/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/zh-hant'

import iconSet from 'quasar/icon-set/material-icons'


import Vue from 'vue'

import Quasar from 'quasar/src/vue-plugin.js';import QList from 'quasar/src/components/item/QList.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Meta from 'quasar/src/plugins/Meta.js';


Vue.use(Quasar, { config: {"notify":{"color":"green-6","position":"top-right"}},lang: lang,iconSet: iconSet,components: {QList},plugins: {Loading,Dialog,Notify,Meta} })
