export function getProfileByMemberId(state) {
  return memberId => state.profile.profileMap[memberId]
}

export function getProfileMap(state) {
  return state.profileMap
}

export function getMyIdDepartments(state) {
  if (!state.myProfile) return null
  return state.myProfile.idDepartments
}

export function getRolePrivileges(state) {
  if (!state.myProfile || !state.myProfile.member) return null
  const roles = state.myProfile.member.roles
  const privileges = new Set()
  roles.forEach(role => {
    if (role.privileges) {
      JSON.parse(role.privileges).forEach(privilege => privileges.add(privilege))
    }
  })
  return Array.from(privileges)
}
