const routes = [
  {
    path: 'survey',
    name: 'Survey',
    component: () => import('pages/survey/Index.vue'),
    meta: {
      title: '表單系統',
      subtitle: 'Survey',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: 'survey-new',
    name: 'SurveyNew',
    component: () => import('pages/survey/SurveyNew.vue'),
    meta: {
      title: '新增表單',
      subtitle: 'SurveyNew',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: 'survey-replies',
    name: 'SurveyReplies',
    component: () => import('pages/survey/Replies.vue'),
    meta: {
      title: '表單回覆',
      subtitle: 'SurveyReplies',
      icon: '',
      requiresAuth: true
    }
  }
]

export default routes
