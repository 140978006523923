import Vue from 'vue'
import axios from 'axios'
import Loading from 'quasar/src/plugins/Loading.js';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = localStorage.getItem('token') || ''

  if (token) {
    config.headers['X-Access-Token'] = token
  }
  Loading.show()
  return config
}, function (error) {
  // Do something with request error
  Loading.hide()
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  Loading.hide()
  return response
}, function (error) {
  Loading.hide()
  return Promise.reject(error)
})

Vue.prototype.$axios = axios
