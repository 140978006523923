import axios from 'axios'
import { authLogin } from 'boot/axiosAPI/auth'

export function initProfile({ commit }) {
  commit('setMyProfile', null)
}

export async function loadProfileByMemberId({ commit, state }, memberId) {
  if (state.profileMap[memberId]) {
    return state.profileMap[memberId]
  } else {
    const profile = await axios
      .get(`/gateway/proxy/core/v1/profiles/${memberId}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      })
      .then(({ data }) => {
        console.log(data)
        if (data.returnCode === '000000') {
          return data.info
        } else {
          return null
        }
      })
    commit('setProfileMap', { memberId, profile })
    return profile
  }
}

export async function getPrivilegeByMicroCode({ commit, state, getters, dispatch }, microCode) {
  if (!state.myProfile) {
    await dispatch('getMyProfile', localStorage.getItem('memberId'))
    console.log(state)
  }

  if (!microCode) return null

  if (state.myProfile && state.myProfile.member) {
    const memberRoles = state.myProfile.member.roles || []
    const privileges = new Set()
    memberRoles.forEach(role => {
      if (role.privileges) {
        JSON.parse(role.privileges).forEach(privilege => privileges.add(privilege))
      }
    })

    const rule = ['EMPLOYEE', 'MANAGER', 'ADMIN']
    const roles = Array.from(privileges)
    if (!roles) return null
    const microRoles = roles.filter(role => role.indexOf(microCode) > -1).map(role => rule.indexOf(role.replace(microCode + '_', ''))).sort((a, b) => b - a)
    return rule[microRoles[0]] || null
  } else {
    return null
  }
}

export async function getMyProfile({ commit, state }, memberId) {
  if (state.myProfile) {
    return state.myProfile
  } else {
    const profile = await axios
      .get(`/gateway/proxy/core/v1/profiles/${memberId}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      })
      .then(({ data }) => {
        console.log(data)
        if (data.returnCode === '000000') {
          return data.info
        } else {
          return null
        }
      })
    commit('setMyProfile', profile)
    return profile
  }
}

export function login({ commit, state }, user) {
  return new Promise((resolve, reject) => {
    authLogin({
      ...user
    })
      .then(({ data }) => {
        const $route = this.$router.currentRoute
        if (data.returnCode === '000000') {
          const { forceChangePw, token, memberId, team, projectCode, status } = data.info
          if (forceChangePw) {
            const query = {
              accessToken: token,
              memberId
            }
            if ($route.query.redirect_url) {
              query.redirect_url = $route.query.redirect_url
            }
            resolve({
              name: 'ForceUpdatePassword',
              query: query
            })
          } else {
            if ($route.query.redirect_url) {
              const searchParams = new URLSearchParams()
              searchParams.append('projectCode', projectCode)
              searchParams.append('accessToken', token)
              searchParams.append('memberId', memberId)
              const redirectUrl =
              $route.query.redirect_url + '?' + searchParams.toString()
              window.location.replace(redirectUrl)
            }
            localStorage.setItem('projectCode', projectCode)
            localStorage.setItem('token', token)
            localStorage.setItem('memberId', memberId)
            localStorage.setItem('team', JSON.stringify(team))
            localStorage.setItem('status', status)
            resolve({
              name: 'BoIndex',
              query: null
            })
          }
        } else {
          resolve()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
