const routes = [
  {
    path: 'lns/course',
    name: 'LnsCourse',
    component: () => import('pages/lns/Course.vue')
  },
  {
    path: 'lns/user',
    name: 'LnsUser',
    component: () => import('pages/lns/User.vue')
  },
  {
    path: 'lns/course-user/:courseID',
    name: 'LnsCourseUser',
    component: () => import('pages/lns/CourseUser.vue')
  }
]

export default routes
