const routes = [
  {
    path: 'mng/project',
    name: 'MngProject',
    component: () => import('pages/mng/Project.vue'),
    meta: {
      title: '工作管理',
      subtitle: 'Task',
      icon: 'ballot',
      requiresAuth: true,
      permissions: [
        { microCode: 'MNG', crud: 15 },
        { microCode: 'COR', crud: 2 }
      ]
    }
  },
  {
    path: 'mng/story/:idProjects',
    name: 'MngStory',
    component: () => import('pages/mng/Story.vue')
  },
  {
    path: 'mng/task',
    name: 'MngTask',
    component: () => import('pages/mng/Task.vue')
  }
]

export default routes
