const routes = [
  {
    path: 'asset-category',
    name: 'AssetCategory',
    component: () => import('pages/assetsManage/Category.vue'),
    meta: {
      title: 'AssetCategory',
      subtitle: '資產分類管理',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'AMS', crud: 3 }]
    }
  },
  {
    path: 'asset',
    name: 'Asset',
    component: () => import('pages/assetsManage/Index.vue'),
    meta: {
      title: 'Asset',
      subtitle: '資產維護',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'AMS', crud: 3 }]
    }
  },
  {
    path: 'asset/:id',
    name: 'AssetDetail',
    component: () => import('pages/assetsManage/Detail.vue'),
    meta: {
      requiresAuth: true,
      permissions: [{ microCode: 'AMS', crud: 3 }]
    }
  },
  {
    path: 'asset-logs',
    name: 'AssetLogs',
    component: () => import('pages/assetsManage/Logs.vue'),
    meta: {
      title: 'AssetLogs',
      subtitle: '資產紀錄',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'AMS', crud: 3 }]
    }
  }
]

export default routes
