const Resource = process.env.RESOURCE ? process.env.RESOURCE : 'Resource'

const routes = [
  {
    path: 'cms/bulletin-reads',
    name: 'CMS-BulletinReads',
    component: () => import('pages/cms/BulletinReads.vue'),
    meta: {
      title: '公告已讀列表',
      subtitle: 'Bulletin Reads',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 15 }]
    }
  },
  {
    path: 'cms/photo-album',
    name: 'PhotoAlbum',
    component: () => import('pages/photoAlbum/Index.vue'),
    meta: {
      title: '相簿',
      subtitle: 'Photo Album',
      icon: 'mdi-image-album',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 15 }]
    }
  },
  {
    path: 'cms/photo-album-new',
    name: 'PhotoAlbumNew',
    component: () => import('pages/photoAlbum/PhotoAlbumNew.vue'),
    meta: {
      title: '新增相簿',
      subtitle: 'Photo Album New',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 1 }]
    }
  },
  {
    path: 'cms/options-general',
    name: 'OptionsGeneral',
    component: () => import('pages/options/General.vue'),
    meta: {
      title: '外部網站設定',
      subtitle: 'CMS Options General',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 7 }]
    }
  },
  {
    path: 'cms/tags',
    name: 'CMSTags',
    component: () => import('pages/cms/Tags.vue'),
    meta: {
      title: '標籤分類',
      subtitle: 'CMS Tag',
      icon: 'mdi-monitor-dashboard',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 7 }]
    }
  },
  {
    path: 'cms/faq',
    name: 'CMS_FAQ',
    component: () => import('pages/cms/FAQ.vue'),
    meta: {
      title: '問與答',
      subtitle: 'FAQ',
      icon: 'mdi-message-question',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 15 }]
    }
  },
  {
    path: 'cms/resource',
    name: 'CMS_Resource',
    component: () => import('pages/cms/' + Resource + '.vue'),
    meta: {
      title: '資源下載管理',
      subtitle: 'Resource',
      icon: 'mdi-folder-upload-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'CMS', crud: 15 }]
    }
  },
  {
    path: 'cms/classifications',
    name: 'CMS_Classifications',
    component: () => import('pages/cms/Classifications.vue')
  },
  {
    path: 'cms/classifications/:classification',
    name: 'CMS_Classification',
    component: () => import('pages/cms/Classification.vue')
  },
  {
    path: 'cms/pages',
    component: () => import('pages/cms/pages/Index.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'CmsPages',
        component: () => import('pages/cms/pages/List.vue')
      },
      {
        path: 'create',
        name: 'CmsPagesCreate',
        component: () => import('pages/cms/pages/Create.vue')
      },
      {
        path: 'edit',
        name: 'CmsPagesEdit',
        component: () => import('pages/cms/pages/Edit.vue')
      }
    ]
  }
]

export default routes
