const routes = [
  {
    path: 'judge/referral-papers/create',
    name: 'JudgeReferralPaperCreate',
    component: () => import('pages/mlshb/judge/referralPaper/CreateReferralPaper.vue')
  },
  {
    path: 'judge/payment-logs',
    name: 'JudgePaymentLogs',
    component: () => import('pages/mlshb/judge/payment/Logs.vue')
  },
  {
    path: 'judge/punishment-notifications',
    name: 'JudgePunishmentNotifications',
    component: () => import('pages/mlshb/judge/punishmentNotification/Index.vue')
  },
  {
    path: 'judge/punishment-notifications/create',
    name: 'JudgePunishmentNotificationsCreate',
    component: () => import('pages/mlshb/judge/punishmentNotification/Create.vue')
  },
  {
    path: 'judge/report',
    name: 'JudgeReport',
    component: () => import('pages/mlshb/judge/report/Index.vue')
  },
  {
    path: 'thpa-person',
    name: 'THPA-Person',
    component: () => import('pages/judge/thpa/Person.vue')
  },
  {
    path: 'audit-person',
    name: 'AuditPerson',
    component: () => import('pages/judge/thpa/AuditPerson.vue')
  },
  {
    path: 'judge-reports',
    name: 'JudgeReports',
    component: () => import('pages/judge/Reports.vue')
  },
  {
    path: 'judge-reports2',
    name: 'JudgeReports2',
    component: () => import('pages/judge/Reports2.vue')
  },
  {
    path: 'judge-total-report',
    name: 'JudgeTotalReport',
    component: () => import('pages/judge/TotalReport.vue')
  }
]

export default routes
