import asset from './asset'
import punch from './punch'
import system from './system'
import bs from './bs'
import event from './event'
import lottery from './lottery'
import legacyJudge from './judge'
import url from './url'
import sheiho from './sheiho'
import mlshb from './mlshb'
import cms from './cms'
import mng from './mng'
import lns from './lns'
import dsc from './dsc'
import coreV2 from './v2/core'
import cmsV2 from './v2/cms'
import core from './core'
import survey from './survey'
//
import judge from './main/judge'
import mainCms from './main/cms'

const LinkPage = process.env.LINKPAGE ? process.env.LINKPAGE : 'Legacy'

const routes = [
  {
    path: '/',
    component: () => import('layouts/NoDrawerLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('pages/Login.vue')
      },
      {
        path: 'cdc-login',
        name: 'CDCLogin',
        component: () => import('pages/CdcLogin.vue')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('pages/public/ResetPassword.vue')
      },
      {
        path: 'force-update-password',
        name: 'ForceUpdatePassword',
        component: () => import('pages/public/ForceUpdatePassword.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'review-ticket',
        name: 'ReviewTicket',
        component: () => import('pages/public/ReviewTicket.vue'),
        meta: {
          requiresAuth: false,
          microCode: 'CSS'
        }
      },
      {
        path: 'redirect',
        name: 'Redirect',
        component: () => import('pages/public/Redirect.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'core',
        name: 'CORE',
        component: () => import('pages/core/Index.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '',
        component: () => import('pages/Index.vue')
      }
    ]
  },
  {
    path: '/system',
    component: () => import('layouts/SystemLayout.vue'),
    children: [...system]
  },
  {
    path: '/private',
    // component: () => import('layouts/MainLayout.vue'),
    components: {
      default: () => import('layouts/DevLayout.vue')
    },
    props: {
      default: {
        sidebar: true
      }
    },
    meta: {
      requiresAuth: true
    },
    children: [
      ...mainCms,
      ...judge,
      ...punch,
      ...asset,
      ...bs,
      ...event,
      ...lottery,
      ...legacyJudge,
      ...sheiho,
      ...mlshb,
      ...cms,
      ...url,
      ...core,
      ...coreV2,
      ...cmsV2,
      ...mng,
      ...lns,
      ...dsc,
      ...survey,
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('pages/Setting.vue'),
        meta: {
          title: 'Setting',
          subtitle: '系統設定',
          icon: 'setting',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 6 }]
        }
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('pages/account/Index.vue'),
        meta: {
          title: 'Account',
          subtitle: '個人帳號管理',
          icon: 'person',
          requiresAuth: true
        }
      },
      {
        path: 'banner',
        name: 'Banner',
        component: () => import('pages/Banner.vue'),
        meta: {
          title: 'Banner',
          subtitle: '首頁輪播圖管理',
          icon: 'burst_mode',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'navigation',
        name: 'Navigation',
        component: () => import('pages/Navigation.vue'),
        meta: {
          title: 'Navigation',
          subtitle: '導覽列管理',
          icon: 'burst_mode',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'note',
        name: 'Note',
        component: () => import('pages/Note.vue'),
        meta: {
          title: 'Note',
          subtitle: '遠端紀錄',
          icon: 'burst_mode',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 2 }]
        }
      },
      {
        path: 'bulletin',
        name: 'Bulletin',
        component: () => import('pages/Bulletin.vue'),
        meta: {
          title: 'Bulletin',
          subtitle: '公告管理',
          icon: 'notifications_none',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'bulletin-dashboard',
        name: 'BulletinDashboard',
        component: () => import('pages/BulletinDashboard.vue'),
        meta: {
          title: 'BulletinDashboard',
          subtitle: '公告總覽',
          icon: 'notifications_none',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'link',
        name: 'Link',
        component: () => import('pages/link/' + LinkPage + '.vue'),
        meta: {
          title: 'Link',
          subtitle: '連結管理',
          icon: 'link',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'article',
        name: 'Article',
        component: () => import('pages/article/Index.vue'),
        meta: {
          title: 'Article',
          subtitle: '內容管理',
          icon: 'chrome_reader_mode',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'article-new',
        name: 'ArticleNew',
        component: () => import('pages/article/ArticleNew.vue'),
        meta: {
          title: 'ArticleNew',
          subtitle: '新增內容',
          icon: 'chrome_reader_mode',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('pages/Category.vue'),
        meta: {
          title: 'Category',
          subtitle: '類別管理',
          icon: 'storage',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'classification',
        name: 'Classification',
        component: () => import('pages/Classification.vue'),
        meta: {
          title: 'Classification',
          subtitle: '分類管理',
          icon: 'view_list',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'product',
        name: 'Product',
        component: () => import('pages/Product.vue'),
        meta: {
          title: 'Product',
          subtitle: '商品管理',
          icon: 'markunread_mailbox',
          requiresAuth: true,
          microCode: 'SHP',
          permissions: [{ microCode: 'SHP', crud: 15 }]
        }
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('pages/ec/Order.vue'),
        meta: {
          title: 'Order',
          subtitle: '訂單管理',
          icon: 'list_alt',
          requiresAuth: true,
          permissions: [{ microCode: 'SHP', crud: 15 }]
        }
      },
      {
        path: 'coupon',
        name: 'Coupon',
        component: () => import('pages/ec/Coupon.vue'),
        meta: {
          title: 'Coupon',
          subtitle: '優惠券管理',
          icon: 'confirmation_number',
          requiresAuth: true,
          permissions: [{ microCode: 'SHP', crud: 15 }]
        }
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('pages/Member.vue'),
        meta: {
          title: 'Member',
          subtitle: '會員管理',
          icon: 'group',
          requiresAuth: true,
          microCode: 'COR',
          permissions: [{ microCode: 'COR', crud: 15 }]
        }
      },
      // {
      //   path: 'task',
      //   name: 'Task',
      //   component: () => import('pages/Task.vue'),
      //   meta: {
      //     title: 'Task',
      //     subtitle: '工作管理',
      //     icon: 'ballot',
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'news',
        name: 'News',
        component: () => import('pages/News.vue'),
        meta: {
          title: 'News',
          subtitle: '新聞管理',
          icon: 'wifi_tethering',
          requiresAuth: true,
          permissions: [{ microCode: 'CMS', crud: 15 }]
        }
      },
      {
        path: 'department',
        name: 'Department',
        component: () => import('pages/Department.vue'),
        meta: {
          title: 'Department',
          subtitle: '部門管理',
          icon: 'folder_shared',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 7 }]
        }
      },
      {
        path: 'employee',
        name: 'Employee',
        component: () => import('pages/Employee.vue'),
        meta: {
          title: 'Employee',
          subtitle: '員工管理',
          icon: 'assignment_ind',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 7 }]
        }
      },
      {
        path: 'dispatch',
        name: 'Dispatch',
        component: () => import('pages/Dispatch.vue'),
        meta: {
          title: 'Dispatch',
          subtitle: '派工系統',
          icon: 'work',
          requiresAuth: true,
          microCode: 'CSS',
          permissions: [{ microCode: 'CSS', crud: 7 }]
        }
      },
      {
        path: 'report',
        name: 'Report',
        component: () => import('pages/Report.vue'),
        meta: {
          title: 'Report',
          subtitle: '回報紀錄',
          icon: 'mdi-text-box-search',
          requiresAuth: true,
          permissions: [{ microCode: 'CSS', crud: 7 }]
        }
      },
      {
        path: 'report/:id',
        name: 'ReportDetail',
        component: () => import('pages/report/Detail.vue'),
        meta: {
          title: 'ReportDetail',
          subtitle: '回報詳細',
          icon: 'mdi-text-box-search',
          requiresAuth: true,
          permissions: [{ microCode: 'CSS', crud: 7 }]
        }
      },
      {
        path: 'email-template',
        name: 'EmailTemplate',
        component: () => import('pages/EmailTemplate.vue'),
        meta: {
          title: 'Email Template',
          subtitle: '信箱模板設定',
          icon: 'mail_outline',
          requiresAuth: true
        }
      },
      {
        path: 'recent-logging',
        name: 'RecentLogging',
        component: () => import('pages/RecentLogging.vue'),
        meta: {
          title: 'RecentLogging',
          subtitle: '請求紀錄',
          icon: 'receipt',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 2 }]
        }
      },
      {
        path: 'judge-case',
        name: 'Judge-Case',
        component: () => import('pages/judge/Case.vue'),
        meta: {
          title: 'Cases',
          subtitle: '案件',
          icon: 'mdi-file-multiple',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'audit',
        name: 'Audit',
        component: () => import('pages/judge/Audit.vue'),
        meta: {
          title: 'Audit',
          subtitle: '稽查紀錄表',
          icon: 'mdi-briefcase-edit',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'audit-question',
        name: 'AuditQuestion',
        component: () => import('pages/judge/AuditQuestion.vue'),
        meta: {
          title: 'AuditQuestion',
          subtitle: '稽查項目管理',
          icon: 'mdi-format-list-text',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'thpa',
        name: 'THPA',
        component: () => import('pages/judge/THPA.vue'),
        meta: {
          icon: '',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'referral-paper',
        name: 'ReferralPaper',
        component: () => import('pages/judge/ReferralPaper.vue'),
        meta: {
          icon: '',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'referral-papers',
        name: 'ReferralPapers',
        component: () => import('pages/judge/ReferralPapers.vue'),
        meta: {
          title: 'ReferralPapers',
          subtitle: '案件移送書',
          icon: 'mdi-briefcase-edit',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'punishment-notification',
        name: 'PunishmentNotification',
        component: () => import('pages/judge/PunishmentNotificationForSHB.vue'),
        meta: {
          title: 'PunishmentNotification',
          subtitle: '案件裁處書',
          icon: 'mdi-briefcase-edit',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'punishment-notifications',
        name: 'PunishmentNotifications',
        component: () => import('pages/judge/PunishmentNotifications.vue'),
        meta: {
          title: 'PunishmentNotifications',
          subtitle: '案件裁處書',
          icon: 'mdi-briefcase-edit',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      // Deprecated
      {
        path: 'form-manager',
        name: 'FormManager',
        component: () => import('pages/judge/FormManager.vue'),
        meta: {
          title: 'FormManager',
          subtitle: '稽查分類管理',
          icon: 'mdi-format-list-bulleted-type',
          requiresAuth: true,
          permissions: [{ microCode: 'JUD', crud: 15 }]
        }
      },
      {
        path: 'permission',
        name: 'Permission',
        component: () => import('pages/Permission.vue'),
        meta: {
          title: 'Member Permission',
          subtitle: '權限管理',
          icon: 'mdi-file-tree',
          requiresAuth: true
        }
      },
      {
        path: 'form/auto-trx-profile',
        component: () => import('pages/forms/AutoTrxProfile.vue')
      },
      {
        path: 'j-breathing/reservation',
        name: 'J-BreathingReservation',
        component: () => import('pages/j-breathing/Reservation.vue'),
        meta: {
          title: 'Reservation',
          subtitle: '派工清單',
          icon: 'menu_book',
          requiresAuth: true,
          permissions: [
            { microCode: 'COR', crud: 15 },
            { microCode: 'SEV', crud: 15 },
            { microCode: 'AIR', crud: 15 },
            { microCode: 'CSS', crud: 15 },
            { microCode: 'SHP', crud: 15 },
            { microCode: 'PAY', crud: 15 }
          ]
        }
      },
      {
        path: 'j-breathing/receivable',
        name: 'J-BreathingReceivable',
        component: () => import('pages/j-breathing/Receivable.vue'),
        meta: {
          title: 'Receivable',
          subtitle: '簡易收款',
          icon: 'menu_book',
          requiresAuth: true
        }
      },
      {
        path: 'j-breathing/reserve/:idReservations',
        name: 'J-BreathingReserveInfo',
        component: () => import('pages/j-breathing/Reserve.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'j-breathing/reserve',
        name: 'J-BreathingReserve',
        component: () => import('pages/j-breathing/Reserve.vue'),
        meta: {
          requiresAuth: true,
          microCode: 'AIR'
        }
      },
      {
        path: 'j-breathing/engineer',
        name: 'J-BreathingEngineer',
        component: () => import('pages/j-breathing/Engineer.vue'),
        meta: {
          title: 'J-BreathingEngineer',
          subtitle: '工程師工單',
          icon: 'menu_book',
          requiresAuth: true,
          permissions: [
            { microCode: 'COR', crud: 15 },
            { microCode: 'SEV', crud: 15 },
            { microCode: 'AIR', crud: 15 },
            { microCode: 'CSS', crud: 15 },
            { microCode: 'SHP', crud: 15 },
            { microCode: 'PAY', crud: 15 }
          ]
        }
      },
      {
        path: 'j-breathing/carry-out/:idReservations',
        name: 'J-BreathingEngineerCarryOut',
        component: () => import('pages/j-breathing/CarryOut.vue'),
        meta: {
          requiresAuth: true,
          microCode: 'AIR'
        }
      },
      {
        path: 'j-breathing/calendar',
        name: 'J-BreathingCalendar',
        component: () => import('pages/j-breathing/Calendar.vue'),
        meta: {
          requiresAuth: true,
          title: 'J-BreathingCalendar',
          subtitle: '行事曆',
          icon: 'mdi-calendar',
          permissions: [{ microCode: 'AIR', crud: 15 }]
        }
      },
      {
        path: 'api/log',
        name: 'ApiLog',
        component: () => import('pages/ApiLog.vue'),
        meta: {
          title: 'API LOG',
          subtitle: 'API LOG',
          icon: '',
          requiresAuth: true,
          permissions: [{ microCode: 'COR', crud: 2 }]
        }
      },
      {
        path: 'sale-group',
        name: 'SaleGroup',
        component: () => import('pages/sales/Group.vue'),
        meta: {
          title: 'SaleGroup',
          subtitle: 'SaleGroup',
          icon: 'mdi-office-building-outline',
          requiresAuth: true,
          permissions: [{ microCode: 'SAL', crud: 15 }]
        }
      },
      {
        path: 'sale-group-detail/:idGroups',
        name: 'SaleGroupDetail',
        component: () => import('pages/sales/GroupDetail.vue')
      },
      {
        path: 'sale-companies',
        name: 'SaleCompanies',
        component: () => import('pages/sales/Companies.vue'),
        meta: {
          title: 'SaleCompanies',
          subtitle: 'SaleCompanies',
          icon: 'mdi-storefront-outline',
          requiresAuth: true,
          permissions: [{ microCode: 'SAL', crud: 15 }]
        }
      },
      {
        path: 'sale-visit/:idCompanies',
        name: 'SaleVisit',
        component: () => import('pages/sales/Visit.vue'),
        meta: {
          title: 'SaleVisit',
          subtitle: 'SaleVisit',
          icon: 'mdi-storefront-outline',
          requiresAuth: true,
          permissions: [{ microCode: 'SAL', crud: 15 }]
        }
      },
      {
        path: 'sale-company-detail/:idCompanies',
        name: 'SaleCompanyDetail',
        component: () => import('pages/sales/CompanyDetail.vue')
      },
      {
        path: 'cropper',
        name: 'Cropper',
        component: () => import('components/cropper/Index.vue')
      },
      {
        path: '',
        name: 'BoIndex',
        component: () => import('src/pages/BoIndex.vue')
      }
    ]
  },
  {
    path: '/dev',
    components: {
      default: () => import('layouts/DevLayout.vue')
    },
    props: {
      default: {
        sidebar: true
      }
    },
    // component: () => import('layouts/DevLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/dev/Index.vue')
      }
      // {
      //   path: 'calendar',
      //   component: () => import('pages/dev/Calendar.vue')
      // }
    ]
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
