import Vue from 'vue'
import Vuex from 'vuex'
import IdleVue from 'idle-vue'

// import example from './module-example'
import report from './module-report'
import app from './module-app'
import profile from './module-profile'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function(/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      // example
      report,
      app,
      profile
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,
    state: {
      fileUrl: process.env.FILE_URL || 'https://file.jway.network'
    },
    getters: {
      fileUrl: state => {
        return state.fileUrl
      }
    }
  })
  const eventsHub = new Vue()

  const idleTime = process.env.AUTO_TIMEOUT_TIME || '300'

  const options = {
    eventEmitter: eventsHub,
    idleTime: idleTime * 1000,
    store: Store,
    startAtIdle: false
  }
  Vue.use(IdleVue, options)
  return Store
}
