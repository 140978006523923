const routes = [
  {
    path: 'core/micros',
    name: 'CoreMicros',
    component: () => import('pages/core/Micros.vue'),
    meta: {
      title: '核心服務',
      subtitle: 'Core Micros',
      icon: 'mdi-shape',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 7 }]
    }
  },
  // {
  //   path: 'core/roles',
  //   name: 'CoreRoles',
  //   component: () => import('pages/core/Roles.vue'),
  //   meta: {
  //     title: '核心角色',
  //     subtitle: 'Core Roles',
  //     icon: 'mdi-human-queue',
  //     requiresAuth: true,
  //     microCode: 'COR'
  //   }
  // },
  {
    path: 'core/role',
    name: 'CoreRole',
    component: () => import('src/pages/core/Role.vue'),
    meta: {
      title: '核心群組',
      subtitle: 'Core Role',
      icon: 'mdi-human-queue',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 15 }]
    }
  },
  {
    path: 'core/path-position',
    name: 'PathPosition',
    component: () => import('pages/core/PathPosition.vue'),
    meta: {
      title: '菜單選項',
      subtitle: 'Path Position',
      icon: '',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 15 }]
    }
  }
]
export default routes
