export function openDialog (state) {
  console.log(state)
  // state = {...state, }
  state.dialog.status = true
}

export function closeDialog (state) {
  console.log(state)
  // state = {...state, }
  state.dialog.status = false
}
