const routes = [
  {
    path: 'accounting',
    name: 'Accounting',
    component: () => import('pages/lottery/Accounting.vue')
    // meta: {
    //         permissions: [{ microCode: 'LOT', crud: 15 }]

    // }
  },
  {
    path: 'scratch/agents',
    name: 'ScratchAgent',
    component: () => import('pages/lottery/scratch/Agent.vue'),
    meta: {
      title: 'ScratchAgent',
      subtitle: '代理人管理',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'LOT', crud: 15 }]
    }
  },
  {
    path: 'scratch/items',
    name: 'ScratchItem',
    component: () => import('pages/lottery/scratch/Item.vue'),
    meta: {
      title: 'ScratchItem',
      subtitle: '刮刮樂品項',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'LOT', crud: 15 }]
    }
  },
  {
    path: 'scratch/inventories',
    name: 'ScratchInventory',
    component: () => import('pages/lottery/scratch/Inventory.vue'),
    meta: {
      title: 'ScratchInventory',
      subtitle: '刮刮樂庫存管理',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'LOT', crud: 15 }]
    }
  },
  {
    path: 'scratch/warehouses',
    name: 'ScratchWarehouse',
    component: () => import('pages/lottery/scratch/Warehouse.vue'),
    meta: {
      title: 'ScratchWarehouse',
      subtitle: '刮刮樂倉庫管理',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'LOT', crud: 15 }]
    }
  },
  {
    path: 'scratch/certs',
    name: 'ScratchCert',
    component: () => import('pages/lottery/scratch/Cert.vue'),
    meta: {
      title: 'ScratchCert',
      subtitle: '刮刮樂證件管理',
      icon: 'mdi-square-edit-outline',
      requiresAuth: true,
      permissions: [{ microCode: 'LOT', crud: 15 }]
    }
  }
]

export default routes
