// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  m: {
    errorCode: {
      500: '內部伺服器錯誤',
      100006: '認證逾期，請重新登入',
      100008: '權限不足',
      100016: '找不到用戶資料',
      100003: '此用戶在不同裝置登入或憑證過期'
    },
    common: {
      add: '新增',
      delete: '刪除',
      update: '更新',
      publish: '發佈',
      hide: '隱藏'
    },
    login: {
      dialog: {
        noAuth: {
          message: '權限不足'
        },
        invalidPassword: {
          message: '密碼錯誤'
        }
      }
    },
    banner: {
      menu: {
        title: 'Banner'
      }
    },
    bulletin: {
      menu: {
        title: 'Bulletin'
      }
    },
    link: {
      menu: {
        title: 'Link'
      }
    },
    category: {
      menu: {
        title: 'Category'
      },
      table: {
        title: '類別管理',
        cover: '類別封面',
        parentCategory: '父類別',
        categoryName: '類別名稱',
        description: '描述',
        dateAdd: '時間'
      }
    },
    classification: {
      menu: {
        title: 'Classification'
      },
      table: {
        title: '類別管理',
        parentClassification: '父類別',
        classificationName: '類別名稱',
        description: '描述',
        dateAdd: '建立日期',
        dateUpd: '修改日期',
        active: '啟用'
      }
    },
    product: {
      menu: {
        title: 'Product'
      },
      table: {
        title: '商品管理',
        cover: '商品封面',
        productName: '商品名稱',
        description: '描述',
        price: '商品價格',
        dateAdd: '時間'
      },
      dialog: {
        productAttr: '商品屬性',
        productCategory: '商品類別',
        productName: '商品名稱',
        productPrice: '商品價格',
        description: '商品描述',
        cover: '商品封面',
        morePhotos: '更多商品圖'
      },
      selector: {
        productAttr: {
          normal: '一般',
          coupon: '禮券',
          autos: '車輛'
        }
      }
    },
    order: {
      menu: {
        title: 'Order'
      }
    },
    coupon: {
      menu: {
        title: 'Coupon'
      }
    },
    member: {
      menu: {
        title: 'Member'
      },
      table: {
        title: '會員管理'
      }
    },
    task: {
      menu: {
        title: 'Task'
      },
      table: {
        title: '工作管理'
      }
    },
    news: {
      menu: {
        title: 'News'
      },
      table: {
        title: '新聞管理'
      }
    },
    article: {
      menu: {
        title: 'Article'
      },
      table: {
        title: '內容管理',
        cover: '封面',
        subject: '標題',
        classification: '分類',
        content: '內容',
        visible: '狀態',
        tags: '標籤',
        hitCounter: '點閱數'
      },
      aboutUs: '關於我們'
    },
    department: {
      menu: {
        title: 'Department'
      },
      table: {
        title: '部門管理',
        parentDepartment: '上層單位',
        departmentName: '部門名稱',
        description: '部門簡介',
        dateAdd: '建檔時間'
      }
    },
    emailTemplate: {
      menu: {
        title: 'Email Template'
      },
      table: {
        title: '信箱模板'
      }
    },
    recentLogging: {
      menu: {
        title: 'Recent Logging'
      },
      table: {
        title: '請求紀錄'
      }
    },
    dispatch: {
      menu: {
        title: 'Dispatch'
      },
      table: {
        title: '派工系統'
      }
    },
    employee: {
      menu: {
        title: 'Employee'
      },
      table: {
        title: '員工管理'
      }
    },
    autoTrxProfile: {
      menu: {
        title: 'Auto Trx Profile'
      },
      table: {
        title: '車輛資料'
      },
      data: {
        orgLicensePlate: '原車牌',
        newLicensePlate: '新車牌',
        buyingPrice: '購入價',
        sellingPrice: '銷售價',
        closingPrice: '成交價',
        vin: '車身號碼',
        mark: '廠牌',
        model: '型號',
        mileage: '里程',
        exteriorColor: '外觀顏色',
        remark: '備註',
        location: '放置地點',
        status: '狀態'
      }
    },
    audit: {
      menu: {
        title: 'Audit'
      },
      table: {
        title: '稽查紀錄'
      }
    },
    referral: {
      menu: {
        title: 'ReferralPaper'
      },
      table: {
        title: '移送書'
      }
    },
    punishment_notification: {
      menu: {
        title: 'PunishmentNotification'
      },
      table: {
        title: '裁處書'
      }
    },
    audit_question: {
      menu: {
        title: 'AuditQuestion'
      },
      table: {
        title: '稽查項目管理'
      }
    },
    reservation: {
      menu: {
        title: 'Reservation'
      },
      table: {
        title: 'Reservation'
      }
    },
    judge: {
      formManager: {
        formCategories: '違反法規',
        forms: '違反法條',
        questionGroups: '處分理由及法令依據'
      },
      auditQuestions: '編號'
    },
    system: {
      gateway: {
        methods: {
          get: '查找',
          post: '新增',
          put: '覆寫',
          patch: '更新',
          delete: '刪除'
        },
        table: {
          api: {
            title: 'API',
            name: '服務名稱',
            url: '服務URL',
            prefix: '服務前綴',
            version: '服務版本'
          },
          endpoint: {
            title: 'Endpoint',
            path: '路徑',
            authentication: '登入驗證',
            expire: '有效時間',
            access: '可使用權限',
            method: '動作',
            service: '服務'
          }
        }
      }
    },
    lottery: {
      agent: {
        title: '代理人管理',
        name: '姓名',
        vat: '身分證字號',
        phone: '聯絡電話',
        birthday: '生日',
        dateUpd: '更新時間'
      },
      cert: {
        title: '經銷證管理',
        imageUrl: '照片',
        certId: '經銷商證號',
        idScratchAgents1: '代理人1',
        idScratchAgents2: '代理人2',
        name: '姓名',
        vat: '身分證字號',
        phone: '聯絡電話',
        birthday: '生日',
        dateUpd: '更新時間'
      },
      item: {
        title: '刮刮樂品項管理',
        idScratchItems: '期號',
        name: '品項名',
        imageUrl: '照片',
        price: '單價',
        maxBonus: '最高獎金',
        startSellDay: '發行日期',
        stopSellDay: '下市日期',
        lastRedeemDay: '兌獎截止日',
        maxIssue: '發行數量(張)',
        maxTop: '最高獎金(元)',
        salesRate: '銷售比率',
        unredeemed: '頭獎未兌領',
        dateAdd: '新增時間',
        dateUpd: '更新時間'
      },
      warehouse: {
        title: '倉庫管理',
        idScratchWarehouses: '倉庫流水號',
        name: '倉庫名',
        imageUrl: '照片',
        address: '地址',
        remark: '備註',
        dateAdd: '新增日期',
        dateUpd: '更新日期'
      },
      inventory: {
        title: '庫存管理',
        idScratchInventories: '庫存流水號',
        idScratchItems: '品項期號',
        idScratchCerts: '經銷證',
        idScratchWarehouses: '倉庫',
        scratchSerial: '序號',
        status: '狀態',
        createdMemberId: '新增者',
        updatedMemberId: '更新者',
        dateAdd: '新增時間',
        dateUpd: '更新時間',
        scratchAllCode: '完整序號'
      }
    }
  }
}
