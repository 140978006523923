const routes = [
  {
    path: 'punch',
    name: 'Punch',
    component: () => import('pages/punch/Index.vue'),
    meta: {
      title: '打卡終端機',
      subtitle: 'Punch',
      icon: 'mdi-card',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 7 }]
    }
  },
  {
    path: 'punch/record',
    name: 'PunchRecord',
    component: () => import('pages/punch/Record.vue'),
    meta: {
      title: '打卡記錄',
      subtitle: 'PunchRecord',
      icon: 'mdi-card',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 7 }]
    }
  },
  {
    path: 'punch-options',
    name: 'PunchOptions',
    component: () => import('pages/punch/Options.vue'),
    meta: {
      title: '打卡規則管理',
      subtitle: 'PunchOptions',
      icon: 'rule',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 7 }]
    }
  },
  {
    path: 'organized-record',
    name: 'OrganizedRecord',
    component: () => import('pages/punch/OrganizedRecord.vue'),
    meta: {
      title: '打卡記錄管理',
      subtitle: 'OrganizedRecord',
      icon: 'timer',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 7 }]
    }
  }
]

export default routes
