// http
import axios from 'axios'
// utils
import { dialog } from './utils.js'
// i18n
import { i18n } from '../i18n.js'

// ReturnCode message
const code = {
  100016: {
    title: 'Profile does not exist',
    message: '此帳號無個人檔案，如需要請聯絡系統人員。'
  }
}

// axios instance
const instance = axios.create()

// axios request
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  const token = window.localStorage.getItem('token') || null
  if (token) {
    config.headers['X-Access-Token'] = token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// axios response
instance.interceptors.response.use((response) => {
  const { data } = response
  const { returnCode, info } = data
  switch (returnCode) {
    case '100001':
      dialog('User not found', '帳號不存在')
      break
    case '100002':
      dialog('Invalid password', i18n.t('m.login.dialog.invalidPassword.message'))
      break
    case '100003':
      dialog('Invalid session token')
      break
    case '100004':
      dialog('Required field not found')
      break
    case '100005':
      dialog('Can not find affilliate profile')
      break
    case '100006':
      dialog('Session expired', i18n.n('m.errorCode.100006'))
      break
    case '100007':
      dialog('The username has already exist')
      break
    case '100008':
      dialog('Unauthorized', i18n.n('m.errorCode.100008'))
      break
    // 輸入密碼失敗三次，帳號鎖定
    case '100009':
      dialog('System Error', '帳號異常，請聯絡系統管理員。')
      break
    case '100010':
      dialog('The National ID SSN had been')
      break
    case '100011':
      dialog('Properties type has wrong')
      break
    case '100012':
      dialog('Snapshot type has wrong')
      break
    case '100013':
      dialog('Snapshot content has wrong')
      break
    case '100014':
      dialog('totalPrice has wrong')
      break
    case '100015':
      dialog('coupon not found')
      break
    case '100016':
      dialog(code[100016].title, code[100016].message)
      break
    case '100017':
      dialog('Password is not valid')
      break
    case '100018':
      dialog('Member does not exist')
      break
    case '100019':
      dialog('Email not verified')
      break
    case '100020':
      dialog('Email template not found')
      break
    case '100021':
      dialog('Email process error')
      break
    case '100022':
      dialog('Email token expired')
      break
    case '100023':
      dialog('Email does not exist')
      break
    case '100024':
      dialog('The project code has already exist')
      break
    case '100400':
      dialog('Bad request', info)
      break
    case '100404':
      dialog('Target instance not found')
      break
    case '100499':
      dialog('Client error exception')
      break
    case '200001':
      dialog('Device does not exist')
      break
    case '300002':
      dialog('Out of stock')
      break
    case '500001':
      dialog('Hash not found')
      break
    case '500002':
      dialog('Transaction failure')
      break
    case '600001':
      dialog('Wallet not found')
      break
    case '900001':
      dialog('Required header not found')
      break
    case '900002':
      dialog('API not found')
      break
    case '900003':
      dialog('Endpoint not found')
      break
    case '900004':
      dialog('Permission denied')
      break
    case '900005':
      dialog('Json parse error')
      break
    case '900006':
      dialog('Target instance not leaf node')
      break
    case '999999':
      dialog('Unknown Error')
      break
    default:
      break
  }
  return response
}, (error) => {
  const { response } = error
  if (response) {
    return Promise.reject(error)
  } else {
    if (!window.navigator.onLine) {
      // No Network
      console.error('Not Network')
    } else {
      return Promise.reject(error)
    }
  }
})

export default instance
