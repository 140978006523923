const routes = [
  {
    path: 'chief-mail-tool',
    name: 'ChiefMailTool',
    component: () => import('pages/mlshb/chiefMail/Tool.vue')
  },
  {
    path: 'system-bulletin',
    name: 'SystemBulletin',
    component: () => import('pages/system/Bulletin.vue')
  },
  {
    path: 'email-parameter',
    name: 'EmailParameter',
    component: () => import('pages/system/EmailParameter.vue')
  },
  {
    path: 'path-category',
    name: 'PathCategory',
    component: () => import('pages/system/PathCategory.vue')
  },
  // {
  //   path: 'path-position',
  //   name: 'PathPosition',
  //   component: () => import('pages/system/PathPosition.vue')
  // },
  {
    path: 'path',
    name: 'Path',
    component: () => import('pages/system/Path.vue')
  },
  {
    path: 'gateway/api',
    name: 'GatewayApi',
    component: () => import('pages/system/gateway/Api.vue')
  },
  {
    path: 'gateway/endpoint',
    name: 'GatewayEndpoint',
    component: () => import('pages/system/gateway/Endpoint.vue')
  },
  {
    path: '',
    component: () => import('pages/system/Index.vue')
  }
]

export default routes
