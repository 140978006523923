import Vue from 'vue'
import Dialog from 'quasar/src/plugins/Dialog.js';

Vue.config.errorHandler = (err, vm, info) => {
  Dialog.create({
    title: 'core-錯誤',
    message: `Erro: ${err}<br /> Info: ${info} <br /> 出現該訊息麻煩「複製」或是「截圖」並聯繫工程師。`,
    html: true
  })
}
