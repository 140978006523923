import Vue from 'vue'

Vue.prototype.$api = {
  normalizeJson(json) {
    if (typeof json === 'object') {
      for (const key in json) {
        if (json[key] === null) {
          delete json[key]
        }
      }
    }
    return JSON.stringify(json)
  },
  async createAddress(address) {
    const response = await fetch('/api/addresses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: this.normalizeJson(address)
    })
    const cargo = await response.json()
    return cargo
  },
  async createMember(member) {
    const response = await fetch('/api/members', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: JSON.stringify(member)
    })
    const cargo = await response.json()
    return cargo
  },
  async createReceivable(receivableRequestForm) {
    const response = await fetch(
      '/gateway/proxy/aircon/v1/receivable/reserve',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify(receivableRequestForm)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async makeReservationMetasForJbreathing(reservationMetas) {
    const response = await fetch('/api/jbreathing-reservation-metas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: JSON.stringify(reservationMetas)
    })
    const cargo = await response.json()
    return cargo
  },
  async makeReservation(reservation) {
    const response = await fetch('/api/reservations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: this.normalizeJson(reservation)
    })
    const cargo = await response.json()
    return cargo
  },
  async makeQuotation(quotation) {
    return this.post('/api/quotations', quotation)
  },
  async makeCarts(carts) {
    return this.post('/api/carts', carts)
  },
  async makeOrderItems(orders) {
    return this.post('/api/order-items', orders)
  },
  async addAutoTrxProfile(autoTrxProfile) {
    if (autoTrxProfile.buyingPrice) {
      autoTrxProfile.buyingPrice = Number(autoTrxProfile.buyingPrice)
    }
    if (autoTrxProfile.sellingPrice) {
      autoTrxProfile.sellingPrice = Number(autoTrxProfile.sellingPrice)
    }
    if (autoTrxProfile.closingPrice) {
      autoTrxProfile.closingPrice = Number(autoTrxProfile.closingPrice)
    }
    if (autoTrxProfile.mileage) {
      autoTrxProfile.mileage = Number(autoTrxProfile.mileage)
    }
    autoTrxProfile.projectCode = localStorage.getItem('projectCode')
    const response = await fetch('/api/auto-trx-profiles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: JSON.stringify(autoTrxProfile)
    })
    const cargo = await response.json()
    return cargo
  },
  async getAddress(id, memberId) {
    const mid = memberId || localStorage.getItem('memberId')
    const response = await fetch(`/api/address/${mid}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getAutoTrxProfiles() {
    const filter = { where: { deleted: false } }
    const response = await fetch(
      `/api/auto-trx-profiles?filter=${JSON.stringify(filter)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async getCategories() {
    const filter = {
      where: { projectCode: window.localStorage.getItem('projectCode') }
    }
    const response = await fetch(
      `/api/categories?filter=${JSON.stringify(filter)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async getEmployeeList() {
    const response = await fetch(
      `/gateway/proxy/core/v1/profiles?size=20&page=0&type=E&projectCode=${window.localStorage.getItem(
        'projectCode'
      )}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async getEngineerReservations(memberId) {
    // const filter = { where: { projectCode: window.localStorage.getItem('projectCode') } }
    const mid = memberId || localStorage.getItem('memberId')
    const url = `/gateway/proxy/aircon/v1/engineer/reservations-profiles/${mid}`
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getProfile(memberId) {
    const mid = memberId || localStorage.getItem('memberId')
    const response = await fetch(`/api/profile/${mid}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getProducts() {
    const filter = {
      where: { projectCode: window.localStorage.getItem('projectCode') }
    }
    const response = await fetch(
      `/api/products?filter=${JSON.stringify(filter)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async getOrder(idOrders) {
    const response = await fetch(`/gateway/proxy/shop/v1/orders/${idOrders}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getReservation(idReservation) {
    const response = await fetch(
      `/api/reservations/${idReservation}?filter={"include": [{"relation": "metas"},{"relation": "quotations"},{"relation": "carts"},{"relation": "orderItems"}]}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async getReservations() {
    // const filter = { where: { projectCode: window.localStorage.getItem('projectCode') } }
    const url = '/gateway/proxy/aircon/v1/reservations-profiles'
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getReservationServiceEngineer(idReservations) {
    const response = await fetch(
      `/gateway/proxy/service/v1/reservations/engineer/${idReservations}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        }
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async applyCouponToken(idOrders, couponToken) {
    const response = await fetch(`/gateway/proxy/shop/v1/orders/${idOrders}/coupon`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: this.normalizeJson({ couponToken })
    })
    const cargo = await response.json()
    return cargo
  },
  async getCoupons() {
    const url = '/gateway/proxy/shop/v1/coupons?size=0'

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async getMerchant() {
    const url = '/gateway/proxy/shop/v1/merchants/me'

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      }
    })
    const cargo = await response.json()
    return cargo
  },
  async issue2Binvoice(orderNumber, salesAmount, invoiceTitle, vat) {
    const response = await fetch('/gateway/proxy/aircon/v1/receivable/invoice/2b', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: this.normalizeJson({ orderNumber, salesAmount, invoiceTitle, vat })
    })
    const cargo = await response.json()
    return cargo
  },
  async issue2Cinvoice(orderNumber, salesAmount) {
    const response = await fetch('/gateway/proxy/aircon/v1/receivable/invoice/2c', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: this.normalizeJson({ orderNumber, salesAmount })
    })
    const cargo = await response.json()
    return cargo
  },
  async updateAutoTrxProfile(autoTrxProfile) {
    if (autoTrxProfile.buyingPrice) {
      autoTrxProfile.buyingPrice = Number(autoTrxProfile.buyingPrice)
    }
    if (autoTrxProfile.sellingPrice) {
      autoTrxProfile.sellingPrice = Number(autoTrxProfile.sellingPrice)
    }
    if (autoTrxProfile.closingPrice) {
      autoTrxProfile.closingPrice = Number(autoTrxProfile.closingPrice)
    }
    if (autoTrxProfile.mileage) {
      autoTrxProfile.mileage = Number(autoTrxProfile.mileage)
    }
    const response = await fetch(
      `/api/auto-trx-profiles/${autoTrxProfile.idAutoTrxProfile}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: this.normalizeJson(autoTrxProfile)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateAddress(idAddresses, address) {
    const response = await fetch(
      `/gateway/proxy/core/v1/addresses/${idAddresses}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify(address)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateCarts(cartPackageId, idProducts, cart) {
    const response = await fetch(
      `/gateway/proxy/shop/v1/carts/${cartPackageId}/${idProducts}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify(cart)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateOrderItem(idOrders, idProducts, orderItem) {
    const response = await fetch(
      `/gateway/proxy/shop/v1/orders/item/${idOrders}/${idProducts}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify(orderItem)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateProfile(memberId, profile) {
    const response = await fetch(
      `/gateway/proxy/core/v1/profiles/${memberId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify(profile)
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updatePaymentMethod(idOrders, paymentMethod) {
    const response = await fetch(
      `/gateway/proxy/shop/v1/orders/payment-method/${idOrders}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify({ paymentMethod })
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateReservationMetas(reservationMetas) {
    const response = await fetch('/gateway/proxy/aircon/v1/reservation-metas', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: JSON.stringify(reservationMetas).replace(
        'idJbreathingReservationMetas',
        'idReservationMetas'
      )
    })
    const cargo = await response.json()
    return cargo
  },
  async updateReservationSignature(idReservations, signature) {
    const response = await fetch(
      `/gateway/proxy/service/v1/reservations/signature/${idReservations}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify({ recipientSign: signature })
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateReservationStatus(idReservations, status) {
    const response = await fetch(
      `/gateway/proxy/service/v1/reservations/status/${idReservations}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        body: JSON.stringify({ reservationStatus: status })
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async updateReservationServiceEngineer(idReservations, memberId) {
    const response = await fetch(
      `/gateway/proxy/service/v1/reservations/engineer/${idReservations}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': localStorage.getItem('token')
        },
        method: 'PUT',
        body: JSON.stringify({ serviceEngineerMemberId: memberId })
      }
    )
    const cargo = await response.json()
    return cargo
  },
  async post(endPoint, body) {
    const response = await fetch(endPoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': localStorage.getItem('token')
      },
      body: JSON.stringify(body)
    })
    const cargo = await response.json()
    return cargo
  }
}
