const routes = [
  {
    path: 'dsc/dispatch',
    name: 'DscDispatch',
    component: () => import('pages/dsc/Dispatch.vue'),
    meta: {
      title: '諮詢回覆',
      subtitle: 'Consultation',
      icon: 'mdi-briefcase',
      permissions: [{ microCode: 'CSS', crud: 15 }],
      requiresAuth: true
    }
  },
  {
    path: 'dsc/classification',
    name: 'DscClassification',
    component: () => import('pages/dsc/Classification.vue'),
    meta: {
      title: '活動管理',
      subtitle: 'Activity Manage',
      icon: 'mdi-book-open-outline',
      permissions: [{ microCode: 'DSC', crud: 15 }],
      requiresAuth: true
    }
  },
  {
    path: 'dsc/form/category',
    name: 'DscFormCategory',
    component: () => import('pages/dsc/form/Category.vue'),
    meta: {
      title: '表單管理',
      subtitle: 'Form Manage',
      icon: 'mdi-form-select',
      permissions: [{ microCode: 'DSC', crud: 15 }],
      requiresAuth: true
    }
  },
  {
    path: 'dsc/form/application',
    name: 'DscFormApplication',
    component: () => import('pages/dsc/form/Application.vue'),
    meta: {
      title: '申請表管理',
      subtitle: 'Application Manage',
      icon: 'mdi-folder-upload-outline',
      permissions: [{ microCode: 'DSC', crud: 15 }],
      requiresAuth: true
    }
  },
  {
    path: 'dsc/institutions',
    name: 'DscInstitution',
    component: () => import('pages/dsc/Institution.vue'),
    meta: {
      title: '醫療機構管理',
      subtitle: 'Institution Manage',
      icon: 'mdi-hospital-building',
      permissions: [{ microCode: 'DSC', crud: 15 }],
      requiresAuth: true
    }
  },
  {
    path: 'dsc/users',
    name: 'DscUser',
    component: () => import('pages/dsc/User.vue'),
    meta: {
      title: '會員管理',
      subtitle: 'User Manage',
      icon: 'mdi-account-circle-outline',
      permissions: [
        { microCode: 'DSC', crud: 15 },
        { microCode: 'COR', crud: 7 }
      ],
      requiresAuth: true
    }
  }
]

export default routes
