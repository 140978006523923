import req from './http'

export const authForgotPassword = (data, redirectUrl = null) => {
  let url = '/gateway/public/core/v1/members/forgot-password'
  if (redirectUrl) {
    url = url + `?redirect_url=${redirectUrl}`
  }
  return req('post', url, data)
}

export const authLogout = (data) => {
  return req('delete', `/gateway/public/core/v1/sessions/${data.token}`)
}

export const authLogin = (data, config = {}) => {
  return req('post', '/gateway/public/core/v1/sessions', data, config)
}

export const getSessionByToken = (token) => {
  return req('get', `/gateway/public/core/v1/sessions/${token}`)
}
