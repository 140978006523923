const routes = [
  {
    path: 'v2/navigation',
    name: 'NavigationV2',
    component: () => import('pages/v2/cms/Navigation.vue'),
    meta: {
      title: 'Navigation',
      subtitle: '導覽列管理',
      icon: 'burst_mode',
      requiresAuth: true,
      permissions: [{ microCode: 'COR', crud: 15 }]
    }
  },
  {
    path: 'v2/cms/resources',
    name: 'CmsResourcesV2',
    component: () => import('pages/v2/cms/Resources')
  }
]

export default routes
