const routes = [
  {
    path: 'review',
    name: 'ReView',
    component: () => import('pages/event/ReView.vue')
  },
  {
    path: 'event',
    name: 'Event',
    component: () => import('pages/v2/es/Index.vue')
  },
  {
    path: 'event/:eventId',
    name: 'EventDetail',
    component: () => import('pages/v2/es/Detail.vue')
  },
  {
    path: 'event/:eventId/applicants',
    name: 'EventApplicants',
    component: () => import('pages/event/Applicants.vue')
  }
]

export default routes
