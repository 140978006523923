const routes = [
  {
    path: 'short-url',
    name: 'ShortUrl',
    component: () => import('pages/shortURL/Index.vue'),
    meta: {
      title: 'Short URL',
      subtitle: '短網址',
      icon: 'mdi-link-box-variant',
      requiresAuth: true,
      microCode: 'SUL',
      permissions: [{ microCode: 'SUL', crud: 15 }]
    }
  }
]

export default routes
