export function openDialog(state, payload) {
  // state = {...state, }
  state.dialog[payload] = true
}

export function closeDialog(state, payload) {
  // state = {...state, }
  state.dialog[payload] = false
}

export function setIdelAutoLogout(state, payload) {
  // state = {...state, }
  state.idelAutoLogout = payload
}

export function settingKeepAlive(state, payload) {
  state.includePage = payload
}
