const routes = [
  {
    path: 'judge',
    component: () => import('pages/mlshb/judge/Index.vue'),
    children: [
      {
        path: 'cases',
        name: 'JudgeCases',
        component: () => import('pages/mlshb/judge/case/Index.vue')
      },
      {
        path: 'cases/create',
        name: 'JudgeCasesCreate',
        component: () => import('pages/mlshb/judge/case/Create.vue')
      },
      {
        path: 'deletes',
        name: 'JudgeDeletes',
        component: () => import('pages/mlshb/judge/delete/Index.vue')
      },
      {
        path: 'categories',
        name: 'JudgeCategories',
        component: () => import('pages/mlshb/judge/category/Index.vue')
      }
    ]
  }
]

export default routes
