import axios from 'axios'
import req from './http2'

// For Judge
const judge = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Access-Token': localStorage.getItem('token')
  }
})

judge.loadFormCategories = () => {
  return axios({
    method: 'GET',
    url: '/api/judge-form-categories'
  })
}

judge.loadForms = (...args) => {
  const filter = args[0].filter
  console.log(args[0])
  return axios({
    method: 'GET',
    url: `/api/judge-forms?filter=${JSON.stringify(filter)}`
  })
}
judge.loadQuestionGroups = (...args) => {
  const filter = args[0].filter
  console.log(args[0])
  return axios({
    method: 'GET',
    url: `/api/judge-question-groups?filter=${JSON.stringify(filter)}`
  })
}
// LegalCase
export const postCase = (data, config) => {
  const url = '/gateway/lb4/backoffice/v1/judge-legal-cases'
  return req.post(url, data, config)
}

export const getCases = (config) => {
  const url = '/api/judge-legal-cases'
  return req.get(url, config)
}

export const getCaseByID = (caseID, config) => {
  const url = `/api/judge-legal-cases/${caseID}`
  return req.get(url, config)
}

// PunishmentNotifications
export const getPunishmentNotifications = (config) => {
  const url = '/gateway/lb4/backoffice/v1/judge-punishment-notifications'
  return req.get(url, config)
}

export const patchPunishmentNotificationsV6 = (idPunishmentNotifications, data, config) => {
  const url = `/nest/backoffice/judge/v6/punishment-notifications/${idPunishmentNotifications}`
  return req.patch(url, data, config)
}

export const getPunishmentNotificationsV6 = (config) => {
  const url = '/nest/backoffice/judge/v6/punishment-notifications'
  return req.get(url, config)
}

// ReferralPapers
export const patchReferralPapers = (referralPaperId, data, config) => {
  const url = `/gateway/lb4/backoffice/v1/judge-referral-papers/${referralPaperId}`
  return req.patch(url, data, config)
}

export const getReferralPapersByID = (referralPaperId, config) => {
  const url = `/gateway/lb4/backoffice/v1/judge-referral-papers/${referralPaperId}`
  return req.get(url, config)
}

export const getReferralPapers = (config) => {
  const url = '/gateway/lb4/backoffice/v1/judge-referral-papers'
  return req.get(url, config)
}

// Categories
export const patchCategories = (idCategories, data) => {
  const url = `/nest/backoffice/judge/v6/categories/${idCategories}`
  return req.patch(url, data)
}

export const postCategories = (data) => {
  const url = '/nest/backoffice/judge/v6/categories'
  return req.post(url, data)
}

export const getCategories = (config) => {
  const url = '/nest/backoffice/judge/v6/categories'
  return req.get(url, config)
}

export const patchQuestions = (idQuestions, data) => {
  const url = `/gateway/lb4/backoffice/v1/judge-questions/${idQuestions}`
  return req.patch(url, data)
}

export const postQuestions = (data) => {
  const url = encodeURI('/gateway/lb4/backoffice/v1/judge-questions')
  return req.post(url, data)
}

export const getQuestions = (...args) => {
  const filter = args[0].filter
  const url = encodeURI(`/gateway/lb4/backoffice/v1/judge-questions?filter=${JSON.stringify(filter)}`)
  return req.get(url)
}

export const postQuestionGroups = (data) => {
  return req.post('/gateway/lb4/backoffice/v1/judge-question-groups', data)
}

export const getQuestionGroups = (...args) => {
  const filter = args[0].filter
  const url = encodeURI(`/gateway/lb4/backoffice/v1/judge-question-groups?filter=${JSON.stringify(filter)}`)
  return req.get(url)
}

export const postForms = (data) => {
  return req.post('/gateway/lb4/backoffice/v1/judge-forms', data)
}

export const getForms = (...args) => {
  const filter = args[0].filter
  const url = encodeURI(`/gateway/lb4/backoffice/v1/judge-forms?filter=${JSON.stringify(filter)}`)
  return req.get(url)
}

export const postFormCtegories = (data) => {
  return req.post('/gateway/lb4/backoffice/v1/judge-form-categories', data)
}

export const getFormCategories = (config) => {
  return req.get('/gateway/lb4/backoffice/v1/judge-form-categories', config)
}

export default judge
